
export default class CordovaFallback {
  constructor () {
    let cordovaCheck;
    cordovaCheck = setInterval(() => {
      if (window.cordova && window.cordova.plugins) {
        document.dispatchEvent(new Event('deviceready'));
        cordovaCheck = null
      }
    }, 150);
    document.addEventListener('deviceready', () => {
      clearInterval(cordovaCheck);
      cordovaCheck = null
    }, false);
    setTimeout(() => {
      if(cordovaCheck) {
        document.dispatchEvent(new Event('deviceready'));
      }
    }, 1500)
  }
}
