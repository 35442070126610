<template>
  <div id="app" :class=$root.theme v-if="$root.ready">
    <navbar class="ms-0" :logoIcon="logo" :burgerMenuIcon="burger"/>
    <div class="container ps-lg-4">
      <div class="row">
        <ul class="navbar-nav d-none d-lg-block col-lg-4">
          <li v-for="(link, key) in $navbar.links" :key="key"
              :class="link.path === $router.currentRoute.path ? 'nav-item active' : 'nav-item'">
            <router-link :to="link.path" class="nav-link py-4 position-relative">{{ $t(link.name) }}
              <span v-if="link.meta.badge" class="badge badge-pill badge-primary">{{ link.meta.badge }}</span>
            </router-link>
          </li>
        </ul>
        <transition :name="transitionName" mode="out-in">
          <router-view class="page-view col-12 col-lg-8" ref="routerview"/>
        </transition>
      </div>
    </div>
    <footer>
      <div class="footer-nav">
        <template v-for="(link, key) in $root.footerLinks">
          <router-link v-if="typeof link === 'object'" :key="key" :to="link.path"
                       class="btn btn-circle"
                       :class="link.class && ` ${link.class}` "
                       v-html="link.content"/>
          <router-link v-if="typeof link === 'string' && link === 'HOME'" :key="key" :to="'/home'"
                       class="btn btn-circle"
                       v-html="home"/>
          <button type="button" class="btn btn-circle"
                  v-if="typeof link === 'string' && link === 'BACK'"
                  :key="key" @click.prevent="$router.back()" v-html="back"/>
        </template>
      </div>
    </footer>
  </div>
</template>

<script>
import logo from '../assets/img/apocourier-logo.svg';
import burger from '../assets/img/burger_menu_icon.svg';
import navbar from '../classes/plugins/navbar/component/index.vue'
import home from '../assets/img/house.svg';
import back from '../assets/img/arrow_icon.svg';

export default {
  name: 'layout',
  data() {
    return {
      transitionName: 'slide-right',
      burger,
      logo,
      home,
      back,
    };
  },
  components: {
    navbar
  },
  async mounted() {
    document.body.addEventListener('click', (event) => {
      !this.isIn(this.$navbar.component.$el, event.target) && this.$navbar.component.isOpen && this.$navbar.component.collapse();
    });
  },
  methods: {
    isIn(container, node) {
      return (node === document.body) ? false : container.contains(node);
    }
  },
  beforeRouteEnter(to, from, next) {
    const toDepth = to.path.split('/').length;
    const fromDepth = from.path.split('/').length;
    this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    next();
  }
};
</script>
<style>
</style>
