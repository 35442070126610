import {Model} from "../classes/plugins/database";
import DeliveryState from './deliveryState';
import {applyMiddlewareWithCustomEncryption} from "dexie-encrypted/dist/applyMiddleware";

/**
 * @classdesc represents a delivery
 * @type {Models.Delivery}
 */
export default class Delivery extends Model {
  static entityName = 'Delivery'
  /**
   * @inheritDoc
   */
  static relations = {
    address: 'Address',
    box: 'Box',
    client: 'Client',
    sender: 'Sender',
    courier: ['Courier'],
    states: ['DeliveryState'],
    measurements: ['Measurement']
  };

  /**
   * @inheritDoc
   */
  static action = {
    notify: 'notify',
    accept: 'accept',
    collect: 'collect',
    start: 'start',
    halt: 'halt',
    resume: 'resume',
    abort: 'abort',
    return: 'return',
    deliver: 'deliver',
    sync: 'sync',
    create: 'create'
  };

  /**
   * defined states in which the delivery can be modified
   */
  static editableStates = {
    processed: 'processed',
    looking: 'looking',
    dispatched: 'dispatched',
    collection_pending: 'collection_pending',
    halted: 'halted',
    resumed: 'resumed',
    boarded: 'boarded'
  };
  /**
   * defined states in which the delivery can be modified
   */
  static possibleStates = {
    processed: 'processed',
    looking: 'looking',
    accepted: 'accepted',
    collection_pending: 'collection_pending',
    boarded: 'boarded',
    dispatched: 'dispatched',
    halted: 'halted',
    resumed: 'resumed',
    aborted: 'aborted',
    delivered: 'delivered',
    returned: 'returned'
  };

  static TEMP_CORRIDORS = [
    'APP.BOX.ROOMTEMP',
    'APP.BOX.COOLTEMP'
  ];

  /**
   * @inheritDoc
   */
  static store = 'id';

  /**
   * @inheritDoc
   */
  static propertyOverrides = {};

  /**
   *
   * @return {boolean}
   */
  get overTemperature() {
    const deliveryAlerts = this.getDeliveryAlerts();
    return !!deliveryAlerts.length && !(deliveryAlerts[deliveryAlerts.length - 1].clearanceTimestamp);
  }

  /**
   * @return {Models.DeliveryState}
   */
  get actualState() {
    let state = new DeliveryState();
    state.__createGetterSetter('name')
    state.__createGetterSetter('timestamp')

    if (this.__data.states) {
      if (vueInstance.$user.segment === 'sender' || !this.overTemperature) {
        state = this.__data.states[0];
      } else {
        state.setName('ACTION_MANDATORY');
        state.setTimestamp(Infinity);
      }
    } else {
      state.setName('PROCESSED');
    }
    return state
  }


  /**
   *
   * @return Object
   */
  get temperature() {
    return vueInstance.$Sensor.getSensor('temperature', this.measurements)
  }

  /**
   *
   * @return Object
   */
  get humidity() {
    return vueInstance.$Sensor.getSensor('humidity', this.measurements)
  }

  /**
   *
   * @return {[Number]}
   */
  get timeline() {
    return this.measurements.map(item => item.timestamp);
  }

  get tempCorridor() {
    return this.__data.minTemp === 2 ? 1 : 0;
  }

  set tempCorridor(value) {
    if (value) {
      this.__data.minTemp = 2;
      this.__data.maxTemp = 8;
    } else {
      this.__data.minTemp = 15;
      this.__data.maxTemp = 25;
    }
  }

  async setData(data, database) {
    const states = []
    if(data.states?.length)
    for(let state of data.states) {
      if(state instanceof DeliveryState) {
        return state
      }
      if(typeof state === 'number') {
        let item = await database.getRepository('DeliveryState').find(state)
        if(!item) {
          item = await database.getRepository('DeliveryState').find(Number(String(state).replace(String(data.id), '')))
        }
        state = item
      }
      states.push(state)
    }
    data.states = states
    return super.setData(data, database);
  }

  /**
   *
   * @return {number}
   */
  getStatusLevel() {
    const states = this.states instanceof Array ? this.states.map(state => state?.name).filter(item => item) : false;
    return states ? states.includes('delivered') || states.includes('aborted') ? 5 :
        states.includes('dispatched') ? 4 :
            states.includes('boarded') ? 3 :
                this.box && this.courier && this.box.state === 'close' && this.courier.length === 1 ? 2 :
                    1 : 0;
  }

  dump() {
    let dump = super.dump.call(this);
    dump.measurements = [];
    !this.measurements && (this.measurements = [])
    this.measurements.map((item, index) => {
      dump.measurements[index] = item.dump();
    });
    return dump;
  }

  static get randomKeyCode() {
    return [...Array(32).keys()].map(() => Math.round(Math.random() * 9)).join('')
  }
}
