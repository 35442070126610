import {Model} from "../classes/plugins/database";
import data from "bootstrap/js/src/dom/data";


/**
 * @classdesc represents a deliveryState
 * @type {Models.DeliveryState}
 */
export default class DeliveryState extends Model {
  static entityName =  'DeliveryState'
  /**
   * @inheritDoc
   */
  static store = 'id';
  /**
   * @inheritDoc
   */
  static relations = {};

  /**
   * @inheritDoc
   */
  static propertyOverrides = {};

}
