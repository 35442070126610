<template>
  <section ref="page" :class="fluid ? 'container-fluid' : 'container'"
    @scroll="scroll">
    <header v-if="!loading" class="header row">
      <slot name="header"></slot>
    </header>
    <main v-if="!loading" class="main row">
      <slot name="main"></slot>
    </main>
    <main v-else class="align-items-center d-flex vh-100 justify-content-center is-loading" v-html="loader"/>
    <footer v-if="!loading" class="footer row">
      <slot name="footer"></slot>
    </footer>
  </section>
</template>
<script>

import PullToRefresh from 'pulltorefreshjs';
import loader from '../assets/img/loading.svg'
export default {

  props: {
    refreshable: Boolean(false),
    fluid: false,
    loader: loader,
    startLoading: false
  },
  data () {
    return {
      loading: true,
      refresher: {}
    };
  },
  watch: {
    loading () {
      this.$forceUpdate();
    },
    refreshable (val) {
      console.log(val);
    }
  },
  name: 'leo-page-default',
  mounted () {
    this.$page = this;
    this.loading = this.startLoading ? this.startLoading : !!this.refreshable;
    this.refresher = this.refreshable ? PullToRefresh.init({
      triggerElement: this.$refs.page,
      mainElement: document.body,
      refreshTimeout: 300,
      distThreshold: 50,
      instructionsPullToRefresh: this.$t('APP.PAGE.PULL_TO_REFRESH'),
      instructionsReleaseToRefresh: this.$t('APP.PAGE.RELEASE_TO_REFRESH'),
      instructionsRefreshing: this.$t('APP.PAGE.REFRESHING'),
      onRefresh: (() => this.refresh()),
      shouldPullToRefresh: (() => {
        return !(this.$refs && this.$refs.page ? this.$refs.page.scrollTop : false);
      })
    }) : void (0);
  },
  methods: {
    refresh () {
      document.body.classList.add('overflow-hidden');
      return this.$root.refresh().catch(error => console.log('Refresh Error', error)).finally(() => {
        /**
         * @type {{$root}} context
         */
        this.$page.loading = false;
        document.body.classList.remove('overflow-hidden');
      });
    },
    scroll (event) {
      this.$root.$emit('page-scroll', { target: this.$el, detail: this.$el.scrollTop, originalEvent: event });
    }
  }
};
</script>
<style>

</style>
