import './index.scss';
import kernel from './classes/plugins';
import Vue from 'vue';
import App from './layout/index.vue';
import page from './pages/default.vue';
import CordovaFallback from './classes/CordovaFallback'
import VueRouter from "vue-router";
import Delivery from "@/models/delivery";

new CordovaFallback()

const onDeviceReady = async () => {
  const {default: TokenInvalid} = await import( './classes/plugins/auth/errors/TokenInvalid');
  const {default: LoginFailed} = await import( './classes/plugins/auth/events/LoginFailed');
  const {default: AuthError} = await import( './classes/plugins/auth/errors/AuthError');
  const {default: FullyInitialized} = await import( './classes/plugins/auth/events/FullyInitialized');
  const {default: LogoutSucceeded} = await import( './classes/plugins/auth/events/LogoutSucceeded');
  const {default: routes} = await import( './pages');
  const {default: strings} = await import( './lang');
  /**
   * cordova Object
   * @namespace
   * @type {{plugins: Object, platformId: String, getAppVersion: Object<String, Function>}}
   * @global
   */
  window.cordova = window.cordova || {};
  window.initialRoute = window.location.hash.replace(/^#/, '');
  window.redirectRoute = null
  const router = new VueRouter({
    routes,
  })

  Vue.use(kernel);
  Vue.use(VueRouter)

  /**
   * @type {Vue}
   */
  window.vueInstance = new Vue({
    router,
    el: '#app',
    components: {App},
    template: '<App v-if="ready"/>',
    data() {
      return {
        ready: false,
        isIos: window.cordova.platformId === 'ios',
        theme: document.body.classList.contains('dark') ? 'dark' : 'light',
        initialTokenTest: true,
        loginDialogId: null,
        messageChannel: window.cordova.platformId === 'browser' ? new MessageChannel() : {},
        initialRefresh: false,
        footerLinks: [],
        lastVisited: {},
        authenticatedLinks: [],
        defaultLinks: [],
        redirectRoute: {}
      };
    },
    async created() {
      const locale = typeof navigator !== 'object' ? strings.defaults.default :
              strings[navigator.language] ? navigator.language :
                  strings.defaults[navigator.language.slice(0, 2)] ? strings.defaults[navigator.language.slice(0, 2)] :
                      strings.defaults.default,
          packageName = 'de.apocourier.aposender',
          host = 'https://app.apocourier.de/';

      await this.$kernel.initBundle(() => import('./classes/plugins/api'), {
        host,
        endpoints: {
          'auth.login': host + 'api/authentication',
          'auth.refresh': host + 'api/refresh_auth',
          'auth.logout': host + 'logout',
          'deliveries': host + 'api/deliveries',
          'couriers': host + 'api/couriers',
          'boxes': host + 'api/box',
          'clients': host + 'api/clients',
          'user': host + 'api/user',
          'settings': host + 'api/settings',
          'device': host + 'api/device',
          'states': host + 'api/states'
        },
        cache: 'no-cache',
        redirect: 'manual',
        networkErrorCallback: (api, error) => {
          this.$dialogs.alert({
            title: this.$t('APP.ERROR.NETWORK_TITLE'),
            message: this.$t('APP.ERROR.NETWORK_MESSAGE'),
            dismissible: true
          });
          return Promise.reject(error);
        }
      }, 'api')
      await this.$kernel.initBundle(() => import('./classes/plugins/i18n'), {
        strings,
        language: locale,
        fallbackLanguage: 'de-DE'
      }, 'i18n')
      await this.$kernel.initBundle(() => import('./classes/plugins/barcode'), {}, 'barcode');
      await this.$kernel.initBundle(() => import('./classes/plugins/bluetooth'), {}, 'bluetooth');
      await this.$kernel.initBundle(() => import('./classes/plugins/connection'), {}, 'connection');
      await this.$kernel.initBundle(() => import('./classes/plugins/gps'), {}, 'gps');
      await this.$kernel.initBundle(() => import('./classes/plugins/auth'), {
        jwt_token: packageName + '_auth',
        refresh_token: packageName + '_refresh',
        segment: packageName + '_segment'
      }, 'auth')
      await this.$kernel.initBundle(() => import('./classes/plugins/nfc'), {}, 'nfc');
      await this.$kernel.initBundle(() => import('./classes/plugins/user'), {
        declareReactive: {
          couriers: [],
          deliveries: [],
          clients: [],
          senders: [],
          pending: [],
          boxes: [],
          states: [],
          history: []
        }
      }, 'user')
      await this.$kernel.initBundle(() => import('./classes/plugins/database'), {
        name: 'ApoCourier Sender',
        version: 1
      }, 'database')
      await this.$kernel.initBundle(() => import('./classes/plugins/firebase'), {
        app: {
          apiKey: 'AIzaSyAdBvkWouXApILV_shnqM_MCtUWNjTLaEo',
          projectId: 'apocourier',
          messagingSenderId: '602369038112',
          appId: '1:602369038112:android:6c84d960c3a4e9b585a945'
        },
        messageConsumer: {
          channels: {
            DELIVERIES: {
              id: 'notification_channel_deliveries',
              name: 'APP.NOTIFICATION_CHANNEL.DELIVERIES',
              description: 'APP.NOTIFICATION_CHANNEL.DELIVERIES_DESCRIPTION',
              importance: 4
            },
            DEFAULT: {
              id: 'notification_channel_default',
              name: 'APP.NOTIFICATION_CHANNEL.DEFAULT',
              description: 'APP.NOTIFICATION_CHANNEL.DEFAULT_DESCRIPTION',
              importance: 4
            },
            CALLS: {
              id: 'notification_channel_call',
              name: 'APP.NOTIFICATION_CHANNEL.CALLS',
              description: 'APP.NOTIFICATION_CHANNEL.CALLS_DESCRIPTION',
              importance: 4
            },
            ACCOUNT: {
              id: 'notification_channel_account',
              name: 'APP.NOTIFICATION_CHANNEL.ACCOUNT',
              description: 'APP.NOTIFICATION_CHANNEL.ACCOUNT_DESCRIPTION',
              importance: 4
            }
          }
        }
      }, 'firebase')
      await this.$kernel.initBundle(() => import('./classes/plugins/permissionhandler'), {}, 'permissionHandler');
      await this.$kernel.initBundle(() => import('./classes/plugins/forms'), {}, 'forms');
      await this.$kernel.initBundle(() => import('./classes/plugins/dialogs'), {}, 'dialogs');
      await this.$kernel.initBundle(() => import('./classes/plugins/navbar'), {}, 'navbar');
      await this.$kernel.initBundle(() => import('./classes/box.js'), {declareReactive: {'model': {}}}, "Box");
      await this.$kernel.initBundle(() => import('./classes/delivery'), {declareReactive: {'model': {}}}, "Delivery");
      await this.$kernel.initBundle(() => import('./classes/sensor.js'), {}, "Sensor");
      await this.$kernel.initBundle(() => import('./classes/clients.js'), {
        declareReactive: {
          model: {},
          clientForm: {},
          clientFormIndex: 0,
          autocompleteClients: [],
          autocompleteValue: '',
          autocompleteRequested: false,
          autocompleteGroup: {}
        }
      }, 'Clients');

      /**
       * check if user is already logged in. if so, send position and check again.
       */
      this.$user.addLoginListener(async () => {
        await this.init()
        await this.checkAuth(this.$user.loggedIn)
        if (this.$user.loggedIn) {
          await this.$database.instanceDatabase(this.$auth.token)
          const gps = await this.$gps.currentPosition({timeout: 1000}).catch()
          gps && this.$api.post('settings', {lat: gps.coords.latitude, lon: gps.coords.longitude}, ['position']);
          window.dispatchEvent(new FullyInitialized(this));
          this.$page.loading = false;
        } else {
          requestAnimationFrame(() => {
            this.$router.redirectedRoute = null;
            this.$page.loading = false;
          });
        }
      });
      await this.$database
          .addModels([
            () => import( './models/delivery'),
            () => import( './models/address'),
            () => import( './models/client'),
            () => import( './models/box'),
            () => import( './models/measurement'),
            () => import( './models/datapoint'),
            () => import( './models/deliveryState'),
            () => import( './models/sender'),
            () => import( './models/courier'),
            () => import( './models/pending'),
          ])

      this.$router.afterEach((to, from) => {
        if (to.meta && to.meta.auth) {
          if (!this.$user.loggedIn && to.meta && to.meta.auth) {
            let resolved = router.resolve(to.fullPath).resolved;
            this.redirectRoute = resolved.matched[0];
            this.redirectRoute.path = resolved.fullPath;
            this.redirectRoute.params = resolved.params;
            return router.replace({name: 'APP.PAGE.LOGIN'});
          } else if (this.$user.loggedIn && to.name === 'APP.PAGE.LOGOUT') {
            return this.$auth && this.app.$auth.logout().then(() => {
              return router.replace({name: 'APP.PAGE.LOGIN'});
            });
          }
        } else {
          if (to.name === 'APP.PAGE.LOGIN' && this.$user.loggedIn) {
            return router.replace({name: 'APP.PAGE.HOME'});
          }
        }
        document.body.dataset.page = to.name;
      })
      /**
       * set up connection Handling
       */
      this.$connection.addCallback('offline', this.offlineCallback);
      this.$connection.addCallback('online', this.onlineCallback);

      /**
       * check for permissions
       */
      if (cordova.platformId !== 'browser')
        this.$permissionHandler
            .requestPermissions(['audio', 'video', 'notification', 'location'])
            .finally(() => {
              this.$forceUpdate();
            });

      /**
       * set up the default notification channel
       */
      window.addEventListener('notification_channel_default', /** @param {NotificationEvent} event */(event) => {
        let options = {
          title: this.$t('APP.NOTIFICATION.DEFAULT_MESSAGE'),
          message: this.$t('APP.NOTIFICATION.DEFAULT_TITLE'),
          button: this.$t('APP.NOTIFICATION.DEFAULT_BUTTON')
        };
        if (event.detail.payload.gcm) {
          options = {
            button: 'OK',
            message: event.detail.payload.gcm.body,
            title: event.detail.payload.gcm.title
          };
        } else if ('title' in event.detail.payload) {
          options = {
            title: event.detail.payload.title,
            message: event.detail.payload.body,
            button: 'OK'
          };
        }

        event.detail.payload.button && (options.button = event.detail.payload.button);

        let uri;

        event.detail.payload.url && (options.completeCallback = () => {
          try {
            uri = new URL(event.detail.payload.url);
            if (uri.pathname === 'blank') {
              return this.$router.push(event.detail.payload.url.replace(/^#/, ''));
            } else {
              return window.open(event.detail.payload.url, 'system');
            }
          } catch (e) {
            console.error('this route isn\'t supported', event.detail.payload.url, uri);
          }
        });
        this.$dialogs.alert(options);
      });

      /**
       * set up the call channel
       */
      window.addEventListener('notification_channel_call', /** @param {NotificationEvent} event */(event) => {
        let url = event.detail.payload.defaultAction ? event.detail.payload.defaultAction : event.detail.payload.url;
        return this.$router.push(url.replace(/^#/, ''));
      });

      /**
       * listen for scroll event; add scrolling class to navigation bar
       */
      this.$on('page-scroll', (event) => {
        let navElement = this.$navbar.component.$el;
        event.detail >= 10 ? navElement.classList.add('scrolled') : navElement.classList.remove('scrolled');
      });

      this.$router.afterEach(() => {
        let navElement = document.querySelector('nav');
        navElement?.classList.remove('scrolled');
      });

      this.$page = {loading: true}

      Vue.component('leo-page-default', page);
      this.ready = true

      this.$page.loading = true;
      /**
       * test the authentication of the user, display messages if necessary
       */
      try {
        await this.$nextTick()
        const tokens = await this.$auth.testRefreshToken()
        if (tokens) {
          await this.$auth.loginSuccess(tokens)
          this.initialTokenTest = false;
          this.$page.loading = false;
        } else {
          this.initialTokenTest = false;
          this.$page.loading = false;
        }
      } catch (e) {
        if (e instanceof TokenInvalid) {
          this.$dialogs.alert({
            title: this.$t('APP.ERROR.' + e.__proto__.constructor.name.toUpperCase() + '_TITLE'),
            message: this.$t('APP.ERROR.' + e.__proto__.constructor.name.toUpperCase() + '_MESSAGE'),
            dismissible: true
          });
        }
        this.initialTokenTest = false;
        this.$page.loading = false;
      }
      let exiting = false
      document.addEventListener('backbutton', () => {
        if (exiting) {
          console.log('exiting app')
          navigator.app?.exitApp();
        }
        if (this.$route.path === '/home') {
          this.$dialogs.alert({
            title: 'App Schließen',
            message: 'Wenn Sie die App schließen möchten, drücken Sie bitte erneut die "Zurück" Taste',
            dismissible: true
          })
          exiting = true
        } else {
          this.$router.back()
        }
      });

      this.$router.afterEach((to) => {
        if (to.name !== 'APP.PAGE.HOME') {
          exiting = false
        }
      })

      /**
       * redirect the user to the login page, and display a message if login is failed
       */
      window.addEventListener('LoginFailed', (event) => {
        if (!this.initialTokenTest && this.$router.currentRoute.name !== 'APP.PAGE.LOGIN' || event.detail instanceof AuthError) {
          this.$auth.logout().then(() => {
            requestAnimationFrame(() => {
              if (!this.loginDialogId) {
                this.loginDialogId = this.$dialogs.alert({
                  title: this.$t('APP.ERROR.LOGIN_FAILED_TITLE'),
                  message: this.$t('APP.ERROR.' + event.detail.message),
                  completeCallback: () => {
                    this.loginDialogId = null;
                  },
                  button: 'OK'
                });
              }
            });
            if (this.$router.currentRoute.name !== 'APP.PAGE.LOGIN') {
              return this.$router.push(this.$router.resolve({name: 'APP.PAGE.LOGIN'}).resolved);
            }
          });
        }
      });

      window.addEventListener('LogoutSucceeded', event => {
        this.updateLinks(this.defaultLinks);
        this.initialRefresh = false;
        if (event.detail instanceof AuthError) {
          this.loginDialogId = this.$dialogs.alert({
            title: this.$t('APP.ERROR.LOGIN_FAILED_TITLE'),
            message: this.$t('APP.ERROR.LOGIN_FAILED'),
            completeCallback: () => {
              this.loginDialogId = null;
            },
            button: 'OK'
          });
        }
      });
    },
    mounted() {

      this.authenticatedLinks = [
        this.$router.resolve({name: 'APP.PAGE.HOME'}).resolved,
        this.$router.resolve({name: 'APP.PAGE.COURIERS'}).resolved,
        this.$router.resolve({name: 'APP.PAGE.CLIENTS'}).resolved,
        this.$router.resolve({name: 'APP.PAGE.BOXES'}).resolved,
        this.$router.resolve({name: 'APP.PAGE.HISTORY'}).resolved,
        // this.$router.getNamedRoute('APP.PAGE.LOCATIONS'),
        this.$router.resolve({name: 'APP.PAGE.SETTINGS'}).resolved
      ];

      this.defaultLinks = [
        this.$router.resolve({name: 'APP.PAGE.SETTINGS'}).resolved,
        this.$router.resolve({name: 'APP.PAGE.LOGIN'}).resolved
      ];

      this.$router.afterEach((to, from) => {
        this.lastVisited = from
        // TODO: manche Informatioen, wie offene Botenanfragen müssen durch das entfernen des unteren codes wieder hinzugefügt werden

        return Promise.allSettled([
          // this.$user.loggedIn ? this.$Delivery.updateAll('Delivery', 'deliveries') : Promise.resolve(),
          this.$user.loggedIn ? this.$Delivery.updateAll('DeliveryState', 'states') : Promise.resolve(),
          // this.$user.loggedIn ?  this.$api.get('user').then(results=>{
          //    console.log('user results',results);
          //    this.$user.user = results;
          //  }) : Promise.resolve()
          // this.$user.loggedIn && this.$user.segment === 'courier' ? this.$Delivery.updateAll('Pending', 'deliveries', ['pending']) : Promise.resolve(),
          // this.$user.loggedIn && this.$user.segment === 'sender' ? this.$Delivery.updateAll('Courier', 'couriers') : Promise.resolve(),
          // this.$user.loggedIn && this.$user.segment === 'sender' ? this.$Delivery.updateAll('Client', 'clients') : Promise.resolve(),
          // this.$user.loggedIn ? this.$Delivery.updateAll('Box', 'boxes') : Promise.resolve()
        ]);
      });

      /**
       * check if router should redirect after login
       */
      if (this.$router.resolve({name: window.initialRoute}) && ['APP.PAGE.HOME', 'APP.PAGE.LOGIN'].indexOf(this.$router.resolve(window.initialRoute).resolved.name) === -1) {
        this.$router.redirectRoute = this.$router.resolve({name: window.initialRoute}).resolved
      }
    },
    methods: {
      /**
       *
       * @param auth
       * @return {Promise<unknown>}
       */
      async checkAuth(auth) {
        let pushPromise = Promise.resolve();
        if (auth) {
          this.updateLinks(this.authenticatedLinks);
          if (window.redirectRoute && window.redirectRoute.path) {
            pushPromise = this.$router.replace(window.redirectRoute.path);
          } else {
            pushPromise = this.$router.replace({name: 'APP.PAGE.HOME'});
          }
        } else {
          this.updateLinks(this.defaultLinks);
          if ((this.$router.currentRoute.name !== 'APP.PAGE.LOGIN' || this.$router.currentRoute.name === 'APP.PAGE.INITIAL') && this.$router.currentRoute.meta && this.$router.currentRoute.meta.auth) {
            pushPromise = this.$router.replace({name: 'APP.PAGE.LOGIN'});
          }
        }
        if (!pushPromise) {
          pushPromise = Promise.resolve();
        }
        await pushPromise
        await this.$nextTick()
        return auth
      },
      async init() {
        const permissions = [
          "android.permission.BLUETOOTH",
          "android.permission.BLUETOOTH_ADMIN",
          "android.permission.BLUETOOTH_CONNECT",
          "android.permission.BLUETOOTH_SCAN",
          "android.permission.BLUETOOTH_ADVERTISE",
          "android.permission.ACCESS_COARSE_LOCATION",
          "android.permission.ACCESS_FINE_LOCATION",
          "android.permission.CAMERA",
          "android.permission.NFC"
        ];
        for (const item of permissions) {
          await new Promise((resolve, reject) => cordova.plugins.permissions.requestPermission(item, resolve, reject))
        }
      },
      /**
       * display an alert, if user is offline.
       */
      offlineCallback() {
      },
      /**
       * remove the alert, if user is online.
       */
      onlineCallback() {
        return (() => {
          try {
            let sync = JSON.parse(window.localStorage.getItem('sync'));
            if (sync) {
              let synced = {};
              Object.keys(sync).reverse().map(async key => {
                if (!synced[key]) {
                  synced[key] = [];
                }
                let tempModel;
                switch (key) {
                  case 'deliveries':
                    this.$page.loading = true;
                    tempModel = null;
                    if (this.$Delivery.model instanceof Delivery) {
                      tempModel = this.$Delivery.model.dump();
                    }
                    await Promise.allSettled(sync[key].reverse().map(async (delivery, index) => {
                      if (synced[key].indexOf(delivery.id) < 0) {
                        synced[key].push(delivery.id);
                        console.log('syncing ' + key + ' ' + delivery.id);
                        let model = new Delivery();
                        await model.setData(delivery, this.$database);
                        this.$Delivery.setDeliveryModel(model);
                        await this.$Delivery.sync();
                        this.$Delivery.setDeliveryModel(null);
                      }
                      sync[key].splice(index, 1);
                      window.localStorage.setItem('sync', JSON.stringify(sync));
                    }));
                    if (tempModel) {
                      let model = new Delivery();
                      await model.setData(tempModel, this.$database);
                      this.$Delivery.setDeliveryModel(model);
                    }
                    this.$page.loading = false;
                    break;
                  case 'boxes':
                    this.$page.loading = true;
                    tempModel = null;
                    if (this.$Box.model instanceof Delivery) {
                      tempModel = this.$Box.model.dump();
                    }
                    await Promise.allSettled(sync[key].reverse().map(async (box, index) => {
                      if (synced[key].indexOf(box.id) < 0) {
                        synced[key].push(box.id);
                        let model = new Box();
                        await model.setData(box, this.$database);
                        this.$Box.setBoxModel(model);
                        await this.$Box.saveSettings();
                        this.$Box.setBoxModel(null);
                      }
                      sync[key].splice(index, 1);
                      window.localStorage.setItem('sync', JSON.stringify(sync));
                    }));

                    if (tempModel) {
                      let model = new Box();
                      await model.setData(tempModel, this.$database);
                      this.$Box.setBoxModel(model);
                    }
                    this.$page.loading = false;
                    break;
                }
              });
            }
          } catch (e) {
          }
        })();
      },
      /**
       * proxy function for the page-update
       * @returns {boolean}
       */
      refresh() {
        return typeof this.refresher === 'function' && this.refresher.call(this);
      }
    }
  });
}
document.addEventListener('deviceready', onDeviceReady, false);
