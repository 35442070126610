var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      ref: "page",
      class: _vm.fluid ? "container-fluid" : "container",
      on: { scroll: _vm.scroll },
    },
    [
      !_vm.loading
        ? _c("header", { staticClass: "header row" }, [_vm._t("header")], 2)
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? _c("main", { staticClass: "main row" }, [_vm._t("main")], 2)
        : _c("main", {
            staticClass:
              "align-items-center d-flex vh-100 justify-content-center is-loading",
            domProps: { innerHTML: _vm._s(_vm.loader) },
          }),
      _vm._v(" "),
      !_vm.loading
        ? _c("footer", { staticClass: "footer row" }, [_vm._t("footer")], 2)
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }