var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$root.ready
    ? _c(
        "div",
        { class: _vm.$root.theme, attrs: { id: "app" } },
        [
          _c("navbar", {
            staticClass: "ms-0",
            attrs: { logoIcon: _vm.logo, burgerMenuIcon: _vm.burger },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "container ps-lg-4" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c(
                  "ul",
                  { staticClass: "navbar-nav d-none d-lg-block col-lg-4" },
                  _vm._l(_vm.$navbar.links, function (link, key) {
                    return _c(
                      "li",
                      {
                        key: key,
                        class:
                          link.path === _vm.$router.currentRoute.path
                            ? "nav-item active"
                            : "nav-item",
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link py-4 position-relative",
                            attrs: { to: link.path },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t(link.name)) + "\n            "
                            ),
                            link.meta.badge
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "badge badge-pill badge-primary",
                                  },
                                  [_vm._v(_vm._s(link.meta.badge))]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: _vm.transitionName, mode: "out-in" } },
                  [
                    _c("router-view", {
                      ref: "routerview",
                      staticClass: "page-view col-12 col-lg-8",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("footer", [
            _c(
              "div",
              { staticClass: "footer-nav" },
              [
                _vm._l(_vm.$root.footerLinks, function (link, key) {
                  return [
                    typeof link === "object"
                      ? _c("router-link", {
                          key: key,
                          staticClass: "btn btn-circle",
                          class: link.class && ` ${link.class}`,
                          attrs: { to: link.path },
                          domProps: { innerHTML: _vm._s(link.content) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    typeof link === "string" && link === "HOME"
                      ? _c("router-link", {
                          key: key,
                          staticClass: "btn btn-circle",
                          attrs: { to: "/home" },
                          domProps: { innerHTML: _vm._s(_vm.home) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    typeof link === "string" && link === "BACK"
                      ? _c("button", {
                          key: key,
                          staticClass: "btn btn-circle",
                          attrs: { type: "button" },
                          domProps: { innerHTML: _vm._s(_vm.back) },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.$router.back()
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }